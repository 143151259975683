import React, { useState } from "react"
import Hero, { CarouselItem } from "../components/Layout/Hero"
import { Subheading } from "../components/Layout/Hero/style"
import styled from "styled-components"
import bgImage from "../../static/img/about-us.png"
import { TanghoTheme } from "../components/Themes"
import SEO from "../components/SEO"

const carouselItem: CarouselItem[] = [
  {
    backgroundImg: "8YxaRe0a15w",
    isVideo: true,
    heading: {
      mobileMargin: "170px 0 0 0",
      desktopMargin: "350px 0 0 0",
      fontColor: "#FCFBFB",
      text: "About Us",
      mobileFontAlign: "center",
      desktopFontAlign: "center",
      desktopFontSize: "96px",
    },
  },
]

const {
  main: { bottleGreen },
} = TanghoTheme

/**
 * Typescript type for Icon component.
 * @property isArrowDown boolean indicating direction in which dropdown arrow is facing.
 */
type InlineIcon = {
  isArrowDown?: boolean
}

/**
 * styled Icon component displaying dropdown arrow icon.
 */
const Icon = styled.img<InlineIcon>`
  display: inline;
  padding: 0 8px;
  ${({ isArrowDown }) => (isArrowDown ? "" : "transform: rotate(180deg);")}
`
/**
 * styled wrapping Container component to wrap collapsible text.
 */
export const Container = styled.div`
  padding: 32px 16px 32px 16px;
  background: ${({
    theme: {
      main: { offWhite },
    },
  }) => offWhite};
`

const About = () => {
  const [isOpen, setOpen] = useState(true)
  const [isArrowDown, setArrowDown] = useState(true)

  /**
   * Function responsible for triggering collapsible text animation.
   */
  const runAnimation = () => {
    setOpen(!isOpen)
    setArrowDown(!isArrowDown)
  }

  return (
    <>
      <SEO
        title="Tangho - About"
        description="Tangho was created on the premise that small changes in the way we manufacture everyday items can have a profound impact in our lives and the wellbeing of the planet."
        image={bgImage}
        url="/about"
      />
      <Hero carouselItems={carouselItem} mobileHeight={375} />
      <Container>
        <Subheading
          fontColor={"#020202"}
          mobileFontAlign="left"
          desktopFontAlign="left"
          mobileFontSize="14px"
          mobileLineHeight={18}
          desktopFontSize="24px"
          desktopLineHeight={31}
          padded="32px 8px"
          maxWidth="600px"
          desktopMargin="80px 0 80px 0"
          mobileMargin="0 auto 0 auto"
        >
          Tangho was created on the premise that small changes in the way we
          manufacture everyday items can have a profound impact in our lives and
          the wellbeing of the planet.
          <br />
          <br />
          In 1955, Milton Pilão, a Refining Maintenance Manager at a Brazilian
          paper mill, invented a series of welding processes that allowed
          refiners to process short fibers, such as Eucalyptus. This production
          method is used in over 90% of paper mills around the globe
          <br />
          <br />
          Two generations later, in 2018, Tangho was founded by Mr. Pilão’s
          grandson with the same ambition: to introduce natural fibers as a raw
          material for the production of paper and other products, but this
          time, in a sustainable and eco-friendly way
        </Subheading>
      </Container>
    </>
  )
}

export default About
